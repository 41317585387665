import React from 'react'
import {GridContainer, Heading, Description, ImageContainer, Links, MyImage} from './styles'
import ProfilePicture from '../../assets/profile_picture.jpg'

const BioContainer = () => (
  <GridContainer>
    <Heading>Tony Bugg</Heading>
    <Description>
      Business coach for leaders, teams, directors, lateral hires - all sectors, consultant in
      distressed situations, mediator
    </Description>
    {/* <Space></Space> */}
    <ImageContainer>
      <MyImage alt={`A portrait of Tony Bugg`} src={ProfilePicture} />
    </ImageContainer>
    <Heading>About</Heading>
    <Description>
      Over the past 20 years, Tony has been involved at the top level on many of the most
      significant restructuring or insolvency deals. Most notable was his leading role as the
      principal lawyer from September 2008 onwards advising the administrators in the insolvency of
      <em> Lehman Brothers International </em>(Europe) (LBIE). He also specialises in strategic
      planning and advisory work and distressed M&A.
      {<br />}
      {<br />}
      Throughout his career, Tony has acted for the full range of businesses regarding insolvency
      and restructuring, including insolvency administrators, creditors and debtors. His clients
      comprise all the major accounting firms, prominent banks, financial institutions, private
      equity and hedge funds, distressed investors and insolvency practitioners.
      {<br />}
      {<br />}
      Tony has also held principal roles in the firm’s management. He was global head of the
      restructuring and insolvency practice between 2006 and 2016, and then became the firm's global
      practice head of Banking between 2016 and 2019.
    </Description>
    {/* <Space></Space> */}
    <Heading>Highlights</Heading>
    <Description>
      As the lead adviser on a majority of the market’s outstanding restructurings and insolvencies,
      Tony has advised:
      {<br />}
      {<br />}
      <em> PricewaterhouseCoopers </em>(PwC) as administrators of LBIE and all other Lehman entities
      in administration, known as the largest and most complex insolvency in history, which, when
      completed, will have involved two hearings before the Supreme Court and five cases before the
      Court of Appeal.
      {<br />}
      {<br />}
      <em> Deloitte </em> as administrators of <em> Waterford Wedgwood </em> in 2009 and the sale of
      the main Irish and UK operations to <em> KPS Capital Partners </em>.{<br />}
      {<br />}
      <em> PwC </em> as administrators of Enron from 2001 onwards, which was then the largest
      bankruptcy reorganisation in U.S. history, and the biggest audit failure leading to the
      dissolution of <em> Arthur Andersen </em>.{<br />}
      {<br />}
      <em> PwC </em> as administrators of <em> MG Rover</em> in 2005 and on the sale of its key
      assets to <em> Nanjing Automobile Group </em> .{<br />}
      {<br />}A major investor of the <em> SPhinX fund </em>, which is in liquidation in Cayman.
      {<br />}
      {<br />}
      <em> PwC </em> as administrators of <em> Petroplus Refining and Marketing </em>.
    </Description>
    {/* <Space></Space> */}
    <Heading>Consultancy</Heading>
    <Description>
      The success of any organisation is influenced by the effectiveness of its leaders and their
      communication and relationship with their teams. I have seen the positive impact of coaching
      and mentoring during my 30 years as a lawyer and practice leader. I am a business coach,
      accredited by <em> Meyler Campbell </em>. I work with leaders, professionals and executives in
      pivotal strategic situations, implementing change and managing personal career transition.
      {<br />}
      {<br />}A partner at <em> Linklaters LLP </em> for 20+ years, I helped build and lead a global
      restructuring and insolvency business and latterly the global banking group. As a lawyer and
      adviser, I led the team throughout the financial crisis and was a lead partner advising the UK
      administrators of <em> Lehman Brothers </em> and <em> Enron Europe </em>. A large part of our
      success was down to nurturing and developing our teams – I want to share this experience
      through coaching.
      {<br />}
      {<br />}I believe coaching for partners is most effective for;
      {<br />}
      {<br />}
      Business leaders and Partners with senior leadership roles– coaching can support leaders to
      create a vision and plan; stress test it; check they have the right people to deliver it; and
      work through how they will communicate with the people in their business and implement their
      strategies Lateral hires - joined Linklaters when I was 40 and have since been involved with
      the recruitment and development of many lawyers and partners so have seen both sides. Coaching
      can optimise success by focusing on integration, cultural fit and the objectives of the
      individual and the organization.
      {<br />}
      {<br />}
      Start Ups - entrepreneurs developing new ideas and enterprises ; will benefit from using an
      experienced coach as a sounding board and trusted adviser as they build their business.
      {<br />}
      {<br />}I continue to be a consultant with <em> Linklaters LLP </em> and will consider other
      roles in distressed situations, including mediation. It can help to have an adviser who sees
      the big picture; understands what stakeholders are looking for; road test options and work
      through consequences.
    </Description>
    {/* <Space></Space> */}
    <Links href="mailto:tony@tonybugg.com" target="_blank" rel="noopener">
      Email
    </Links>
    <Links href="https://www.linkedin.com/in/tony-bugg-7539ba/" target="_blank" rel="noopener">
      LinkedIn
    </Links>
  </GridContainer>
)

export default BioContainer
