import {createGlobalStyle} from 'styled-components'

export const GlobalStyle = createGlobalStyle`

body {
    font-family: 'neue-haas-grotesk-display', sans-serif;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
`
