import styled from 'styled-components'

export const GridContainer = styled.div`
  margin: 20px;
  display: grid;
  grid-template-columns: 0.75fr 2fr 2fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 8px;
  }
`

export const Heading = styled.h1`
  margin: 0px;
  grid-row: span 1;
  font-size: 18px;
  font-weight: 600;
`

export const Description = styled.h2`
  font-family: 'Times New Roman', Times, serif;
  margin: 0px;
  grid-row: span 1;
  font-size: 18px;
  font-weight: 500;
  margin: 0 25% 16px 0;

  @media (max-width: 800px) {
    margin: 8px 0 16px 0;
  }
`
export const ImageContainer = styled.div`
  grid-row: span 5;
  top: 20px;
  position: sticky;
  height: 90vh;
  box-sizing: border-box;

  @media (max-width: 800px) {
    top: 0px;
    height: 50vh;
    position: relative;
    left: 0px;
    margin: 0 0 16px 0;
  }
`

export const Space = styled.div`
  margin: 0;
`

export const Links = styled.a`
  grid-row: span 1;
  font-size: 18px;
  font-weight: 500;
  grid-row: span 1;
  text-decoration: underline;
  color: #808080;

  :hover {
    text-decoration: none;
  }
`

export const MyImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: relative;
`
