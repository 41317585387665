import React from 'react'
import {GlobalStyle} from './styles'
import BioContainer from './components/Home'

const App = () => {
  return (
    <>
      <GlobalStyle />
      <BioContainer />
    </>
  )
}

export default App
